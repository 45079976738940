import {makeStyles} from "@material-ui/core/styles";
import React from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
    heading: {
        color: '#FFFFFF',
        margin: '15px 0 0 15px',
    },
}));

const NoResults = () => {
    // Get the classes from the useStyles function
    const classes = useStyles();

    return (
        <Grid item xs={12}>
            <Grid container spacing={3}>
                <Typography className={classes.heading} variant="h5" component="h5">
                    No results found, please edit your configuration
                </Typography>
            </Grid>
        </Grid>
    )
};

export default NoResults;
