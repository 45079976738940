// Import custom styles
import {styles} from './select-form-styles';
import React, {Component} from "react";
import {withStyles} from '@material-ui/core/styles';
import FormControl from "@material-ui/core/FormControl";
import Typography from "@material-ui/core/Typography";
import TextField from '@material-ui/core/TextField';
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Calculator from "../calculator/calculator";
import FormHelperText from "@material-ui/core/FormHelperText";
import Autocomplete from '@material-ui/lab/Autocomplete';
import Select from "@material-ui/core/Select";
import Results from '../results/results';
import MenuItem from "@material-ui/core/MenuItem";
// Import SVG icons
import CPULogo from '../../assets/cpu.svg';
import MBLogo from '../../assets/motherboard.svg';
import GPULogo from '../../assets/gpu.svg';
import RAMLogo from '../../assets/ram.svg';
import SSDLogo from '../../assets/ssd.svg';
import HDDLogo from '../../assets/hdd.svg';
import ODDLogo from '../../assets/cd.svg';
import InputLabel from "@material-ui/core/InputLabel";
// Import FontAwesome
let FontAwesome = require('react-fontawesome');

class SelectForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            cpuParentHasError: false,
            cpuChildHasError: false,
            motherboardHasError: false,
            isSubmitted: false,
            form: {
                cpuParent: null,
                cpuChild: null,
                motherboard: null,
                gpuParent: null,
                gpuChild: null,
                gpuChildQuantity: 1,
                ramComponent: null,
                ramQuantity: 1,
                ssdComponent: null,
                ssdQuantity: 1,
                hddComponent: null,
                hddQuantity: 1,
                oddComponent: null,
                totalWattage: 0
            },
            componentNames: {
                cpuParentName: null,
                cpuChildName: null,
                motherboardName: null,
                gpuParentName: null,
                gpuChildName: null,
                ramComponentName: null,
                ssdComponentName: null,
                hddComponentName: null,
                oddComponentName: null,
            },
            componentWattages: {
                cpuChildWattage: 0,
                motherboardWattage: 0,
                gpuChildWattage: 0,
                ramComponentWattage: 0,
                ssdComponentWattage: 0,
                hddComponentWattage: 0,
                oddComponentWattage: 0
            }
        }
    }

    // Set the state of the form object to an array of the form values and
    // call the setNameAndWattageStates function in a callback function
    // Also set the error values to false to clear any displayed error messages
    // This is for the quantity select options
    handleChange = name => event => {
        this.setState({
            form: {
                ...this.state.form,
                [name]: event.target.value
            },
            cpuParentHasError: false,
            cpuChildHasError: false,
            motherboardHasError: false
        }, () => {
            this.setNameAndWattageStates();
        });
    };

    // Set the state of the form object to the option object values and
    // call the setNameAndWattageStates function in a callback function
    // Also set the error values to false to clear any displayed error messages
    // This is for the autocomplete select options
    handleAutocompleteChange = (name, option) => {
        this.setState({
            form: {
                ...this.state.form,
                [name]: option
            },
            cpuParentHasError: false,
            cpuChildHasError: false,
            motherboardHasError: false
        }, () => {
            this.setNameAndWattageStates();
        });
    };

    // A function to handle a change in the values for the cpuParent Autocomplete field
    handleCpuParentChange = name => event => {
        let cpuParent = null, cpuChild = null, motherboard = null, gpuParent = null, gpuChild = null,
            ramComponent = null, ssdComponent = null, hddComponent = null, oddComponent = null
        if (event.target.value) {
            cpuParent = event.target.value
            cpuChild = ''
            motherboard = this.state.form.motherboard
            gpuParent = this.state.form.gpuParent
            gpuChild = this.state.form.gpuChild
            ramComponent = this.state.form.ramComponent
            ssdComponent = this.state.form.ssdComponent
            hddComponent = this.state.form.hddComponent
            oddComponent = this.state.form.oddComponent
        }
        this.setState({
            form: {
                ...this.state.form,
                cpuParent,
                cpuChild,
                motherboard,
                gpuParent,
                gpuChild,
                ramComponent,
                ssdComponent,
                hddComponent,
                oddComponent,
            },
            cpuParentHasError: false,
            cpuChildHasError: false,
            motherboardHasError: false
        }, () => {
            this.setNameAndWattageStates();
        });
    };

    // A function to handle a change in the values for the cpuChild Autocomplete field
    handleCpuChildChange = (name, option) => {
        let cpuChild = null, motherboard = null, gpuParent = null, gpuChild = null, ramComponent = null,
            ssdComponent = null, hddComponent = null, oddComponent = null
        if (option) {
            cpuChild = option
            motherboard = this.state.form.motherboard
            gpuParent = this.state.form.gpuParent
            gpuChild = this.state.form.gpuChild
            ramComponent = this.state.form.ramComponent
            ssdComponent = this.state.form.ssdComponent
            hddComponent = this.state.form.hddComponent
            oddComponent = this.state.form.oddComponent
        }
        this.setState({
            form: {
                ...this.state.form,
                cpuChild,
                motherboard,
                gpuParent,
                gpuChild,
                ramComponent,
                ssdComponent,
                hddComponent,
                oddComponent,
            },
            cpuParentHasError: false,
            cpuChildHasError: false,
            motherboardHasError: false
        }, () => {
            this.setNameAndWattageStates();
        });
    };

    // A function to handle a change in the values for the motherboard Autocomplete field
    handleMotherboardChange = (name, option) => {
        let motherboard = null, gpuParent = null, gpuChild = null, ramComponent = null,
            ssdComponent = null, hddComponent = null, oddComponent = null
        if (option) {
            motherboard = option
            gpuParent = this.state.form.gpuParent
            gpuChild = this.state.form.gpuChild
            ramComponent = this.state.form.ramComponent
            ssdComponent = this.state.form.ssdComponent
            hddComponent = this.state.form.hddComponent
            oddComponent = this.state.form.oddComponent
        }
        this.setState({
            form: {
                ...this.state.form,
                motherboard,
                gpuParent,
                gpuChild,
                ramComponent,
                ssdComponent,
                hddComponent,
                oddComponent,
            },
            cpuParentHasError: false,
            cpuChildHasError: false,
            motherboardHasError: false
        }, () => {
            this.setNameAndWattageStates();
        });
    };

    // A function to handle a change in the values for the gpuParent Autocomplete field
    handleGpuParentChange = name => event => {
        let gpuParent = null, gpuChild = null
        if (event.target.value) {
            gpuParent = event.target.value
        }
        this.setState({
            form: {
                ...this.state.form,
                gpuParent,
                gpuChild,
            },
            cpuParentHasError: false,
            cpuChildHasError: false,
            motherboardHasError: false
        }, () => {
            this.setNameAndWattageStates();
        });
    };

    // Set the state of the componentNames and componentWattage objects
    // Call the calculateTotalWattage() function in a callback function
    setNameAndWattageStates = () => {
        let cpuParentName = null, cpuChildName = null, motherboardName = null, gpuParentName = null,
            gpuChildName = null, ramComponentName = null, ssdComponentName = null, hddComponentName = null,
            oddComponentName = null, cpuChildWattage = 0, motherboardWattage = 0, gpuChildWattage = 0,
            ramComponentWattage = 0, ssdComponentWattage = 0, hddComponentWattage = 0,
            oddComponentWattage = 0

        if (this.state.form.cpuParent) {
            cpuParentName = this.state.form.cpuParent
        }
        if (this.state.form.cpuChild) {
            cpuChildName = this.state.form.cpuChild.name
            cpuChildWattage = Number(this.state.form.cpuChild.wattage)
        }
        if (this.state.form.motherboard) {
            motherboardName = this.state.form.motherboard.name
            motherboardWattage = Number(this.state.form.motherboard.wattage)
        }
        if (this.state.form.gpuParent) {
            gpuParentName = this.state.form.gpuParent
        }
        if (this.state.form.gpuChild) {
            gpuChildName = this.state.form.gpuChild.name
            gpuChildWattage = Number(this.state.form.gpuChild.wattage)
        }
        if (this.state.form.ramComponent) {
            ramComponentName = this.state.form.ramComponent.name
            ramComponentWattage = Number(this.state.form.ramComponent.wattage)
        }
        if (this.state.form.ssdComponent) {
            ssdComponentName = this.state.form.ssdComponent.name
            ssdComponentWattage = Number(this.state.form.ssdComponent.wattage)
        }
        if (this.state.form.hddComponent) {
            hddComponentName = this.state.form.hddComponent.name
            hddComponentWattage = Number(this.state.form.hddComponent.wattage)
        }
        if (this.state.form.oddComponent) {
            oddComponentName = this.state.form.oddComponent.name
            oddComponentWattage = Number(this.state.form.oddComponent.wattage)
        }

        this.setState({
            componentNames: {
                cpuParentName,
                cpuChildName,
                motherboardName,
                gpuParentName,
                gpuChildName,
                ramComponentName,
                ssdComponentName,
                hddComponentName,
                oddComponentName,
            },
            componentWattages: {
                cpuChildWattage,
                motherboardWattage,
                gpuChildWattage,
                ramComponentWattage,
                ssdComponentWattage,
                hddComponentWattage,
                oddComponentWattage,
            }
        }, () => {
            this.calculateTotalWattage();
        });
    };

    // A function that calculates the total wattage and sets it as the totalWattage state
    calculateTotalWattage = () => {
        // Convert all wattage values to ints or set to 0 if NaN
        const totalCpuWattage = this.state.componentWattages.cpuChildWattage ?
            this.state.componentWattages.cpuChildWattage : 0;
        const totalMotherboardWattage = this.state.componentWattages.motherboardWattage ?
            this.state.componentWattages.motherboardWattage : 0;
        const totalGpuWattage = this.state.componentWattages.gpuChildWattage ?
            this.state.componentWattages.gpuChildWattage * this.state.form.gpuChildQuantity : 0;
        const totalRamWattage = this.state.componentWattages.ramComponentWattage ?
            this.state.componentWattages.ramComponentWattage * this.state.form.ramQuantity : 0;
        const totalSsdWattage = this.state.componentWattages.ssdComponentWattage ?
            this.state.componentWattages.ssdComponentWattage * this.state.form.ssdQuantity : 0;
        const totalHddWattage = NaN ? 0 : this.state.componentWattages.hddComponentWattage ?
            this.state.componentWattages.hddComponentWattage * this.state.form.hddQuantity : 0;
        const totalOddWattage = NaN ? 0 : this.state.componentWattages.oddComponentWattage ?
            this.state.componentWattages.oddComponentWattage : 0;

        // Create array of values to be reduced to sum
        const allWattageValues = [
            totalCpuWattage, totalMotherboardWattage, totalGpuWattage, totalRamWattage,
            totalSsdWattage, totalHddWattage, totalOddWattage
        ];

        // Reduce function to return total value
        const totalWattage = allWattageValues.reduce(
            (accQuantity, item) => accQuantity + item, 0
        );

        // Set the totalWattage state to the result
        this.setState({
            form: {
                ...this.state.form,
                totalWattage
            }
        });
    };

    // A function to reset the form when clicked the reset button - passed in as a prop to the calculator component
    resetForm = event => {
        this.setState({
                form: {
                    cpuParent: null,
                    cpuChild: null,
                    motherboard: null,
                    gpuParent: null,
                    gpuChild: null,
                    gpuChildQuantity: 1,
                    ramComponent: null,
                    ramQuantity: 1,
                    ssdComponent: null,
                    ssdQuantity: 1,
                    hddComponent: null,
                    hddQuantity: 1,
                    oddComponent: null,
                    totalWattage: 0
                },
                cpuParentHasError: false,
                cpuChildHasError: false,
                motherboardHasError: false,
            },
            () => this.setNameAndWattageStates()
        );
    };

    // A function that gets called to submit the form data to the backend API - called in the submitForm function
    postSearchRecord = () => {
        fetch('https://api.calculator.bgfg.co.uk/calculator/search/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${process.env.REACT_APP_TOKEN}`
            },
            body: JSON.stringify({
                cpu_component_name: this.state.componentNames.cpuParentName,
                cpu_subcomponent_name: this.state.componentNames.cpuChildName,
                motherboard_name: this.state.componentNames.motherboardName,
                gpu_component_name: this.state.componentNames.gpuParentName,
                gpu_subcomponent_name: this.state.componentNames.gpuChildName,
                gpu_subcomponent_quantity: Number(this.state.form.gpuChildQuantity),
                ram_component_name: this.state.componentNames.ramComponentName,
                ram_component_quantity: Number(this.state.form.ramQuantity),
                ssd_component_name: this.state.componentNames.ssdComponentName,
                ssd_component_quantity: Number(this.state.form.ssdQuantity),
                hdd_component_name: this.state.componentNames.hddComponentName,
                hdd_component_quantity: Number(this.state.form.hddQuantity),
                optical_drive_name: this.state.componentNames.oddComponentName,
            })
        }).then(res => res.json())
            .then(res =>
                this.setState({isSubmitted: true})
            )
            .catch(error => console.log(error.message));
    };

    // Checks the form for any errors and submits it if all checks pass
    submitForm = event => {
        let cpuParentHasError = false, cpuChildHasError = false, motherboardHasError = false;

        if (!this.state.form.cpuParent) {
            cpuParentHasError = true;
        }
        if (!this.state.form.cpuChild) {
            cpuChildHasError = true;
        }
        if (!this.state.form.motherboard) {
            motherboardHasError = true;
        }
        this.setState(
            {cpuParentHasError, cpuChildHasError, motherboardHasError},
            () => {
                if (!this.state.cpuParentHasError && !this.state.cpuChildHasError && !this.state.motherboardHasError) {
                    this.postSearchRecord();
                }
            },
        );
    };

    // Go back to SelectForm component when the 'Edit Configuration' button is clicked in ResultsHeader
    onEditConfigClick = () => {
        this.setState({isSubmitted: false});
    }

    render() {
        // Destructure the state
        const {
            cpuParentHasError, cpuChildHasError, motherboardHasError, isSubmitted,
            form: {
                cpuParent, cpuChild, motherboard, gpuParent, gpuChild, gpuChildQuantity, ramComponent, ramQuantity,
                ssdComponent, ssdQuantity, hddComponent, hddQuantity, oddComponent, totalWattage
            },
            componentNames: {
                cpuParentName, gpuParentName, ssdComponentName, hddComponentName
            }
        } = this.state;

        // Destructure the props
        const {
            classes, cpuParents, cpuChildrenAmd, cpuChildrenIntel, motherboards, gpuParents, gpuChildrenAmd,
            gpuChildrenNvidia, ramList, ssdList, hddList, oddList
        } = this.props;

        // General function to render the correct dropdown values depending on the received props - this
        // will be used to render the correct values for each component autocomplete dropdown section
        const renderComponentList = (stateVal, componentList, isDisabled, name, label) => {
            return (
                componentList &&
                <Autocomplete
                    classes={{
                        // Render different class based on the relevant state value
                        root: stateVal ? classes.rootHasVal : classes.rootHasNoVal,
                        paper: classes.paper,
                        listbox: classes.listbox,
                        option: classes.option,
                        clearIndicator: classes.clearIndicator,
                        popupIndicator: classes.popupIndicator,
                        inputRoot: classes.inputRoot,
                        input: classes.input,
                        noOptions: classes.noOptions,
                    }}
                    value={stateVal}
                    options={componentList}
                    disabled={isDisabled}
                    name={name}
                    getOptionLabel={(option) => option.name}
                    onChange={
                        (event, value, reason) => {
                            if (name === "cpuChild") {
                                this.handleCpuChildChange(name, value);
                            } else if (name === "motherboard") {
                                this.handleMotherboardChange(name, value);
                            } else {
                                this.handleAutocompleteChange(name, value);
                            }
                        }
                    }
                    style={{width: '100%'}}
                    renderInput={
                        (params) =>
                            <TextField
                                {...params}
                                name={name}
                                label={label}
                                InputLabelProps={{
                                    required: name === 'cpuParent' || name === 'cpuChild' || name === 'motherboard',
                                }}
                                variant="outlined"
                            />
                    }
                />
            )
        };

        // General function to render the correct dropdown values depending on the received props - this
        // will be used to render the correct values for each quantity select dropdown section
        const renderQuantityDropdown = (value, name, arrayLength) => {
            return (
                <Select
                    classes={{
                        root: classes.selectRoot,
                        icon: classes.icon,
                    }}
                    MenuProps={{classes: {paper: classes.selectPaper}}}
                    value={value}
                    onChange={this.handleChange(name)}
                    inputProps={{
                        name: name,
                        id: name,
                    }}
                >
                    {
                        [...Array(arrayLength)].map((e, i) => {
                            return (
                                <MenuItem key={i}
                                          value={i + 1}>{i + 1}</MenuItem>
                            )
                        })
                    }
                </Select>
            )
        };

        const renderComponentParentDropdown = (labelId, labelVal, value, name, componentList) => {
            return (
                <React.Fragment>
                    <InputLabel
                        classes={{
                            root: classes.selectLabelRoot,
                        }}
                        id={labelId}
                        required={name === 'cpuParent'}
                        shrink={value}
                    >
                        {labelVal}
                    </InputLabel>
                    <Select
                        labelId={labelId}
                        classes={{
                            root: classes.selectRoot,
                            icon: classes.icon,
                        }}
                        MenuProps={{classes: {paper: classes.selectPaper}}}
                        value={value}
                        onChange={name === 'cpuParent' ? this.handleCpuParentChange(name) : this.handleGpuParentChange(name)}
                        label={labelVal}
                        inputProps={{
                            name: name,
                            id: name,
                        }}
                    >
                        {
                            name === 'cpuParent' ? (
                                <MenuItem value={null}>
                                    Select Brand
                                </MenuItem>
                            ) : (
                                <MenuItem value={null}>
                                    Select Chipset
                                </MenuItem>
                            )
                        }
                        {
                            componentList.map(component => (
                                <MenuItem key={component.id}
                                          value={component.name}
                                >
                                    {component.name}
                                </MenuItem>
                            ))
                        }
                    </Select>
                </React.Fragment>
            )
        };

        return (
            <React.Fragment>
                {
                    // Render the results component if the isSubmitted state is true. If false, render the select form
                    isSubmitted ? (
                        <Results
                            componentQuantities={this.state.form}
                            componentNames={this.state.componentNames}
                            componentWattages={this.state.componentWattages}
                            isSubmitted={isSubmitted}
                            editConfiguration={this.onEditConfigClick}
                        />
                    ) : (
                        <Grid container className={classes.componentContainer} spacing={3}>
                            {/* Form with select dropdowns to choose relevant components and quantity */}
                            <Grid item xs={12} md={8}>
                                <div>
                                    <Typography className={classes.formTitle} variant="h5" component="h1">
                                        Choose Your Components:
                                    </Typography>
                                </div>
                                <Box className={classes.formContainer} p={2}>

                                    <form>

                                        <div className={classes.formSection}>
                                            <div>
                                                <img className={classes.svgIcon} src={CPULogo} alt="CPU Icon"/>
                                                <Typography className={classes.formSubtitle} variant="h6"
                                                            component="h6">
                                                    Central Processing Unit (CPU)
                                                </Typography>
                                            </div>
                                            <div>
                                                <FormControl
                                                    variant="outlined"
                                                    className={classes.formControlCpuSmall}
                                                    classes={{
                                                        root: cpuParent ? classes.quantityRootHasVal : classes.quantityRootHasNoVal,
                                                    }}
                                                >
                                                    {
                                                        renderComponentParentDropdown("cpu-parent", "Select Brand", cpuParent, 'cpuParent', cpuParents)
                                                    }
                                                </FormControl>
                                                {cpuParentHasError &&
                                                <FormHelperText classes={{root: classes.formHelper}}>This is required!</FormHelperText>}
                                                {/* Options change based on CPU parent option */}
                                                <FormControl className={classes.formControlCpuLarge}
                                                             error={cpuChildHasError}>
                                                    {
                                                        cpuParentName === 'AMD' ? (
                                                            renderComponentList(
                                                                cpuChild, cpuChildrenAmd, !cpuParent, "cpuChild", "Select Series"
                                                            )
                                                        ) : (
                                                            renderComponentList(
                                                                cpuChild, cpuChildrenIntel, !cpuParent, "cpuChild", "Select Series"
                                                            )
                                                        )
                                                    }
                                                    {cpuChildHasError &&
                                                    <FormHelperText>This is required!</FormHelperText>}
                                                </FormControl>
                                            </div>
                                        </div>

                                        <div className={classes.formSection}>
                                            <div>
                                                <img className={classes.svgIcon} src={MBLogo} alt="Motherboard Icon"/>
                                                <Typography className={classes.formSubtitle} variant="h6"
                                                            component="h6">
                                                    Motherboard
                                                </Typography>
                                            </div>
                                            <div>
                                                <FormControl className={classes.formControlLarge}
                                                             error={motherboardHasError}>
                                                    {
                                                        renderComponentList(
                                                            motherboard, motherboards, cpuChild === null, "motherboard", "Select a Motherboard"
                                                        )
                                                    }
                                                    {motherboardHasError &&
                                                    <FormHelperText>This is required!</FormHelperText>}
                                                </FormControl>
                                            </div>
                                        </div>

                                        <div className={classes.formSection}>
                                            <div>
                                                <img className={classes.svgIcon} src={GPULogo} alt="GPU Icon"/>
                                                <Typography className={classes.formSubtitle} variant="h6"
                                                            component="h6">
                                                    Graphics Processing Unit (GPU)
                                                </Typography>
                                            </div>
                                            <div>
                                                <div>
                                                    <FormControl
                                                        disabled={!motherboard}
                                                        variant="outlined"
                                                        className={classes.formControlLarge}
                                                        classes={{
                                                            root: gpuParent ? classes.quantityRootHasVal : classes.quantityRootHasNoVal,
                                                        }}
                                                    >
                                                        {
                                                            renderComponentParentDropdown("gpu-parent", "Select Chipset", gpuParent, 'gpuParent', gpuParents)
                                                        }
                                                    </FormControl>
                                                </div>
                                                <div className={classes.gpuSubForm}>
                                                    {/* Options change based on GPU parent option */}
                                                    <FormControl className={classes.formControlMedium}>
                                                        {
                                                            gpuParentName === 'AMD' ? (
                                                                renderComponentList(
                                                                    gpuChild, gpuChildrenAmd, !gpuParent, "gpuChild", "Select Series"
                                                                )
                                                            ) : (
                                                                renderComponentList(
                                                                    gpuChild, gpuChildrenNvidia, !gpuParent, "gpuChild", "Select Series"
                                                                )
                                                            )
                                                        }
                                                    </FormControl>
                                                    <FontAwesome name="times" className={classes.timesIcon}/>
                                                    <FormControl
                                                        disabled={!gpuChild}
                                                        variant="outlined"
                                                        className={classes.formControlSmall}
                                                        classes={{
                                                            root: gpuChild ? classes.quantityRootHasVal : classes.quantityRootHasNoVal,
                                                        }}
                                                    >
                                                        {
                                                            renderQuantityDropdown(gpuChildQuantity, 'gpuChildQuantity', 2)
                                                        }
                                                    </FormControl>
                                                </div>
                                            </div>
                                        </div>

                                        <div className={classes.formSection}>
                                            <div>
                                                <img className={classes.svgIcon} src={RAMLogo} alt="RAM Icon"/>
                                                <Typography className={classes.formSubtitle} variant="h6"
                                                            component="h6">
                                                    Random Access Memory (RAM)
                                                </Typography>
                                            </div>
                                            <div>
                                                <div>
                                                    <FormControl className={classes.formControlMedium}>
                                                        {
                                                            renderComponentList(
                                                                ramComponent, ramList, !motherboard, "ramComponent", "Select Your Memory"
                                                            )
                                                        }
                                                    </FormControl>
                                                    <FontAwesome name="times" className={classes.timesIcon}/>
                                                    <FormControl
                                                        disabled={!ramComponent}
                                                        variant="outlined"
                                                        className={classes.formControlSmall}
                                                        classes={{
                                                            root: ramComponent ? classes.quantityRootHasVal : classes.quantityRootHasNoVal,
                                                        }}
                                                    >
                                                        {
                                                            renderQuantityDropdown(ramQuantity, 'ramQuantity', 6)
                                                        }
                                                    </FormControl>
                                                </div>
                                            </div>
                                        </div>

                                        <div className={classes.formSection}>
                                            <div>
                                                <img className={classes.svgIcon} src={SSDLogo} alt="SSD Icon"/>
                                                <Typography className={classes.formSubtitle} variant="h6"
                                                            component="h6">
                                                    Solid State Drive (SSD)
                                                </Typography>
                                            </div>
                                            <div>
                                                <div>
                                                    <FormControl className={classes.formControlMedium}>
                                                        {
                                                            renderComponentList(
                                                                ssdComponent, ssdList, !motherboard, "ssdComponent", "Select a Solid State Drive"
                                                            )
                                                        }
                                                    </FormControl>
                                                    <FontAwesome name="times" className={classes.timesIcon}/>
                                                    <FormControl
                                                        disabled={!ssdComponent || ssdComponentName === 'Not Installed'}
                                                        variant="outlined"
                                                        className={classes.formControlSmall}
                                                        classes={{
                                                            root: ssdComponent && ssdComponentName !== 'Not Installed' ?
                                                                classes.quantityRootHasVal : classes.quantityRootHasNoVal,
                                                        }}
                                                    >
                                                        {
                                                            renderQuantityDropdown(ssdQuantity, 'ssdQuantity', 8)
                                                        }
                                                    </FormControl>
                                                </div>
                                            </div>
                                        </div>

                                        <div className={classes.formSection}>
                                            <div>
                                                <img className={classes.svgIcon} src={HDDLogo} alt="HDD Icon"/>
                                                <Typography className={classes.formSubtitle} variant="h6"
                                                            component="h6">
                                                    Hard Disk Drive (HDD)
                                                </Typography>
                                            </div>
                                            <div>
                                                <div>
                                                    <FormControl className={classes.formControlMedium}>
                                                        {
                                                            renderComponentList(
                                                                hddComponent, hddList, !motherboard, "hddComponent", "Select a Hard Drive"
                                                            )
                                                        }
                                                    </FormControl>
                                                    <FontAwesome name="times" className={classes.timesIcon}/>
                                                    <FormControl
                                                        disabled={!hddComponent || hddComponentName === 'Not Installed'}
                                                        variant="outlined"
                                                        className={classes.formControlSmall}
                                                        classes={{
                                                            root: hddComponent && hddComponentName !== 'Not Installed' ?
                                                                classes.quantityRootHasVal : classes.quantityRootHasNoVal,
                                                        }}
                                                    >
                                                        {
                                                            renderQuantityDropdown(hddQuantity, 'hddQuantity', 8)
                                                        }
                                                    </FormControl>
                                                </div>
                                            </div>
                                        </div>

                                        <div className={classes.finalFormSection}>
                                            <div>
                                                <img className={classes.svgIcon} src={ODDLogo} alt="ODD Icon"/>
                                                <Typography className={classes.formSubtitle} variant="h6"
                                                            component="h6">
                                                    Optical Drive (CD/DVD/BLU-RAY)
                                                </Typography>
                                            </div>
                                            <div>
                                                <div>
                                                    <FormControl className={classes.formControlLarge}>
                                                        {
                                                            renderComponentList(
                                                                oddComponent, oddList, !motherboard, "oddComponent", "Select an Optical Drive"
                                                            )
                                                        }
                                                    </FormControl>
                                                </div>
                                            </div>
                                        </div>

                                    </form>

                                </Box>
                            </Grid>

                            {/* Calculator component that displays the total wattage of the selected components */}
                            <Grid item xs={12} md={4}>
                                <Box className={classes.calculatorContainer} p={2}>
                                    <Calculator
                                        totalWattage={totalWattage}
                                        resetForm={this.resetForm}
                                        submitForm={this.submitForm}
                                    />
                                </Box>
                            </Grid>

                        </Grid>
                    )
                }
            </React.Fragment>
        )
    }
}

// Wrap the SelectForm component with the withStyles HOC to give is access to the styles
export default withStyles(styles)(SelectForm);
