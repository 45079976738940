import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Header from "./components/header/header";
import App from './App';
import * as serviceWorker from './serviceWorker';

const routing = (
  <React.StrictMode>
    {/*
      Commented out Header as not needed for now
      <Header/>
    */}
    <App/>
  </React.StrictMode>
);

ReactDOM.render(routing, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
