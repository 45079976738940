export const styles = theme => ({
    configWrapper: {
        backgroundColor: '#1E1E24',
        marginBottom: '60px',
        paddingTop: '40px',
        paddingBottom: '40px',
        [theme.breakpoints.down('xs')]: {
            marginBottom: '25px',
        },
    },
    configContainer: {
        paddingLeft: '10%',
        paddingRight: '10%',
    },
    headerTitle: {
        fontWeight: 'bold',
    },
    lineBreak: {
        width: '100%',
        margin: '15px auto',
    },
    svgIcon: {
        display: 'inline',
        width: '30px',
        marginRight: '10px'
    },
    formSubtitle: {
        fontWeight: 'normal',
        display: 'inline',
        position: 'absolute',
        transform: 'translateY(5px)',
        fontSize: '14px',
        [theme.breakpoints.up('md')]: {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            maxWidth: '15%',
            transform: 'translateY(3px)',
            fontSize: '1rem',
        }
    },
    showContainer: {
        fontWeight: 'normal',
        fontSize: '14px',
        paddingTop: 0,
        cursor: 'pointer',
    },
    showIcon: {
        marginLeft: '10px',
    },
    componentType: {
        color: '#DB3236',
        marginRight: '10px',
        textTransform: 'uppercase',
    },
    editButton: {
        border: '1px solid #FFFFFF',
        color: '#FFFFFF',
        marginRight: '15px',
        fontWeight: 'bold',
        [theme.breakpoints.down('sm')]: {
            border: 'none',
        },
        "&:hover": {
            border: '1px solid #A8A8AC',
            [theme.breakpoints.down('sm')]: {
                border: 'none',
                color: '#A8A8AC',
            },
        },
    },
    editButtonText: {
        [theme.breakpoints.down('sm')]: {
            textDecoration: 'underline',
            "&:hover": {
                textDecoration: 'underline',
            }
        },
    },
    chevronIcon: {
        marginRight: '10px',
        [theme.breakpoints.up('md')]: {
            visibility: 'hidden',
        },
    },
    penIcon: {
        marginLeft: '10px',
        color: '#A5A5A7',
        [theme.breakpoints.up('md')]: {
            visibility: 'hidden',
        },
    },
    optionSection: {
        [theme.breakpoints.down('sm')]: {
            padding: '12px',
        },
    },
    hidden: {
        display: 'none',
    },
});
