// Import custom styles
import {styles} from './results-header-mobile-styles';
import React, {Component} from "react";
import {withStyles} from '@material-ui/core/styles';
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
// Import SVG icons
import CPULogo from '../../assets/cpu.svg';
import MBLogo from '../../assets/motherboard.svg';
import GPULogo from '../../assets/gpu.svg';
import RAMLogo from '../../assets/ram.svg';
import SSDLogo from '../../assets/ssd.svg';
import HDDLogo from '../../assets/hdd.svg';
import ODDLogo from '../../assets/cd.svg';
// Import FontAwesome
let FontAwesome = require('react-fontawesome');

class ResultsHeaderMobile extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isHidden: true,
        }
    }

    // Shows the chosen options on click
    showOptions = () => {
        this.setState({
            isHidden: false
        })
    }

    // Hides the chosen options on click
    hideOptions = () => {
        this.setState({
            isHidden: true
        })
    }

    render() {
        // Destructure props
        const {classes, componentQuantities, componentNames, editConfiguration} = this.props;

        // Destructure state
        const {isHidden} = this.state;

        // A function to render some of the component details
        const renderComponentDetails = () => {
            return (
                <React.Fragment>
                    <Grid item xs={12} md={3} className={classes.optionSection}>
                        <img className={classes.svgIcon} src={RAMLogo} alt="RAM Icon"/>
                        <Typography className={classes.formSubtitle} variant="h6" component="h6">
                            <span className={classes.componentType}>RAM</span>
                            <span>
                                {componentNames.ramComponentName ?
                                    `${componentNames.ramComponentName} x ${componentQuantities.ramQuantity}` : "None"}
                            </span>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={3} className={classes.optionSection}>
                        <img className={classes.svgIcon} src={SSDLogo} alt="SSD Icon"/>
                        <Typography className={classes.formSubtitle} variant="h6" component="h6">
                            <span className={classes.componentType}>SSD</span>
                            <span>
                                {componentNames.ssdComponentName ? componentNames.ssdComponentName : "None"}
                                {componentNames.ssdComponentName && componentNames.ssdComponentName !== 'Not Installed' ?
                                    ` x ${componentQuantities.ssdQuantity}` : ''}
                            </span>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={3} className={classes.optionSection}>
                        <img className={classes.svgIcon} src={HDDLogo} alt="HDD Icon"/>
                        <Typography className={classes.formSubtitle} variant="h6" component="h6">
                            <span className={classes.componentType}>HDD</span>
                            <span>
                                {componentNames.hddComponentName ? componentNames.hddComponentName : "None"}
                                {componentNames.hddComponentName && componentNames.hddComponentName !== 'Not Installed' ?
                                    ` x ${componentQuantities.hddQuantity}` : ''}
                            </span>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={3} className={classes.optionSection}>
                        <img className={classes.svgIcon} src={ODDLogo} alt="CPU Icon"/>
                        <Typography className={classes.formSubtitle} variant="h6" component="h6">
                            <span className={classes.componentType}>OPTICAL DRIVE</span>
                            <span>
                                {componentNames.oddComponentName ? componentNames.oddComponentName : "None"}
                            </span>
                        </Typography>
                    </Grid>
                </React.Fragment>
            )
        }

        return (
            <Box className={classes.configWrapper}>
                <Container className={classes.configContainer} maxWidth='xl' disableGutters>
                    <Grid container spacing={3}>
                        <Typography className={classes.headerTitle} variant="h5" component="h1">
                            Your Configuration
                        </Typography>
                        <hr className={classes.lineBreak}/>
                        <Grid item xs={12} md={3}>
                            <img className={classes.svgIcon} src={CPULogo} alt="CPU Icon"/>
                            <Typography className={classes.formSubtitle} variant="h6" component="h6">
                                <span className={classes.componentType}>CPU</span>
                                <span>
                                {componentNames.cpuChildName ? componentNames.cpuChildName : "None"}
                            </span>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <img className={classes.svgIcon} src={MBLogo} alt="Motherboard Icon"/>
                            <Typography className={classes.formSubtitle} variant="h6" component="h6">
                                <span className={classes.componentType}>MOBO</span>
                                <span>
                                    {componentNames.motherboardName ? componentNames.motherboardName : "None"}
                            </span>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <img className={classes.svgIcon} src={GPULogo} alt="GPU Icon"/>
                            <Typography className={classes.formSubtitle} variant="h6" component="h6">
                                <span className={classes.componentType}>GPU</span>
                                <span>
                                {componentNames.gpuChildName ? componentNames.gpuChildName : "None"}
                                    {componentNames.gpuChildName ?
                                        ` x ${componentQuantities.gpuChildQuantity}` : ''}
                            </span>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={3} className={!isHidden ? classes.hidden : ''}>
                            <Typography
                                className={classes.showContainer}
                                variant="h6"
                                component="h6"
                                onClick={this.showOptions}
                            >
                            <span>
                                Show more
                            </span>
                                <FontAwesome className={classes.showIcon} name='chevron-down'/>
                            </Typography>
                        </Grid>
                        <div className={isHidden ? classes.hidden : ''}>
                            {
                                renderComponentDetails()
                            }
                        </div>
                        <Grid item xs={12} md={3} className={isHidden ? classes.hidden : ''}>
                            <Typography
                                className={classes.showContainer}
                                variant="h6"
                                component="h6"
                                onClick={this.hideOptions}
                            >
                            <span>
                                Show less
                            </span>
                                <FontAwesome className={classes.showIcon} name='chevron-up'/>
                            </Typography>
                        </Grid>
                        <hr className={classes.lineBreak}/>
                        <Button
                            className={classes.editButton}
                            variant="outlined"
                            onClick={editConfiguration}
                        >
                            <FontAwesome className={classes.chevronIcon} name='chevron-left'/>
                            <span className={classes.editButtonText}>Edit Configuration</span>
                            <FontAwesome className={classes.penIcon} name='pen'/>
                        </Button>
                    </Grid>
                </Container>
            </Box>
        )
    }
}

export default withStyles(styles)(ResultsHeaderMobile);
