// Import style sheet
import {styles} from "./results-styles";
import React, {Component} from "react";
import {withStyles} from '@material-ui/core/styles';
import ResultsHeader from "../results-header/results-header";
import ResultsHeaderMobile from "../results-header-mobile/results-header-mobile";
import FilterForm from "../filter-form/filter-form";
import ResultCard from "../result-card/result-card";
import NoResults from "../no-results/no-resutls";
import Spinner from "../spinner/spinner";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
// Import PNG and SVG images
import Modular from '../../assets/modular.svg';
import NonModular from '../../assets/non-modular.svg';
import Titanium80Plus from '../../assets/80-plus-titanium.png';
import Platinum80Plus from '../../assets/80-plus-platinum.png';
import Gold80Plus from '../../assets/80-plus-gold.png';
import Silver80Plus from '../../assets/80-plus-silver.png';
import Bronze80Plus from '../../assets/80-plus-bronze.png';
import White80Plus from '../../assets/80-plus-white.png';

class Results extends Component {
    constructor(props) {
        super(props);

        this.state = {
            componentQuantities: this.props.componentQuantities,
            componentNames: this.props.componentNames,
            componentWattages: this.props.componentWattages,
            totalWattage: this.props.componentQuantities.totalWattage,
            allResults: [],
            modularResults: [],
            nonModularResults: [],
            titaniumResults: [],
            platinumResults: [],
            goldResults: [],
            silverResults: [],
            bronzeResults: [],
            whiteResults: [],
            noFilters: true,
            isLoading: true,
            checkboxStateData: {
                isModular: false,
                isNonModular: false,
                isTitanium: false,
                isPlatinum: false,
                isGold: false,
                isSilver: false,
                isBronze: false,
                isWhite: false,
            },
            filterDialogOpen: false,
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.getProducts();
    }

    // Get the full list of all products, and call the getAllResults function to filter the matching results
    getProducts() {
        fetch('https://api.calculator.bgfg.co.uk/calculator/products/', {
            method: 'GET',
            headers: {
                'Authorization': `Token ${process.env.REACT_APP_TOKEN}`
            }
        }).then(res => res.json())
            .then(res => this.getAllResults(res))
            // ------------------------------ Catch and log any errors ------------------------------
            .catch(error => console.log(error))
    }

    // Filter initial products list to the products that match the user's total wattage to get the results
    getAllResults = products => {
        const allResults = products.filter(
            // Products for which the selected total wattage falls within their min/max wattage range
            product =>
                product.max_wattage >= this.state.totalWattage && product.min_wattage <= this.state.totalWattage
        );
        this.setState({
            allResults,
            isLoading: false
        });
    }

    // Function for setting state of filter form checkboxes
    handleModularChange = event => {
        this.setState({
                checkboxStateData: {
                    ...this.state.checkboxStateData,
                    isModular: !this.state.checkboxStateData.isModular
                }
            },
            () => {
                this.filterCheckboxResults();
            }
        );
    }

    // Function for setting state of filter form checkboxes
    handleNonModularChange = event => {
        this.setState({
                checkboxStateData: {
                    ...this.state.checkboxStateData,
                    isNonModular: !this.state.checkboxStateData.isNonModular
                }
            },
            () => {
                this.filterCheckboxResults();
            }
        );
    }

    // Function for setting state of filter form checkboxes
    handleTitaniumChange = event => {
        this.setState({
                checkboxStateData: {
                    ...this.state.checkboxStateData,
                    isTitanium: !this.state.checkboxStateData.isTitanium
                }
            },
            () => {
                this.filterCheckboxResults();
            }
        );
    }

    // Function for setting state of filter form checkboxes
    handlePlatinumChange = event => {
        this.setState({
                checkboxStateData: {
                    ...this.state.checkboxStateData,
                    isPlatinum: !this.state.checkboxStateData.isPlatinum
                }
            },
            () => {
                this.filterCheckboxResults();
            }
        );
    }

    // Function for setting state of filter form checkboxes
    handleGoldChange = event => {
        this.setState({
                checkboxStateData: {
                    ...this.state.checkboxStateData,
                    isGold: !this.state.checkboxStateData.isGold
                }
            },
            () => {
                this.filterCheckboxResults();
            }
        );
    }

    // Function for setting state of filter form checkboxes
    handleSilverChange = event => {
        this.setState({
                checkboxStateData: {
                    ...this.state.checkboxStateData,
                    isSilver: !this.state.checkboxStateData.isSilver
                }
            },
            () => {
                this.filterCheckboxResults();
            }
        );
    }

    // Function for setting state of filter form checkboxes
    handleBronzeChange = event => {
        this.setState({
                checkboxStateData: {
                    ...this.state.checkboxStateData,
                    isBronze: !this.state.checkboxStateData.isBronze
                }
            },
            () => {
                this.filterCheckboxResults();
            }
        );
    }

    // Function for setting state of filter form checkboxes
    handleWhiteChange = event => {
        this.setState({
                checkboxStateData: {
                    ...this.state.checkboxStateData,
                    isWhite: !this.state.checkboxStateData.isWhite
                }
            },
            () => {
                this.filterCheckboxResults();
            }
        );
    }

    // Filters the results based on checkbox selections
    filterCheckboxResults = () => {
        let modularResults = [], nonModularResults = [], titaniumResults = [], platinumResults = [],
            goldResults = [], silverResults = [], bronzeResults = [], whiteResults = [], noFilters = true

        // Function to filter the 80 Plus results based on the 80 Plus checkbox choices and modularity choice
        const category80PlusFilter = (resultsArr) => {
            if (this.state.checkboxStateData.isTitanium) {
                titaniumResults = resultsArr.filter(
                    result => result.category_80_plus === 'titanium'
                );
                noFilters = false;
            }
            if (this.state.checkboxStateData.isPlatinum) {
                platinumResults = resultsArr.filter(
                    result => result.category_80_plus === 'platinum'
                );
                noFilters = false;
            }
            if (this.state.checkboxStateData.isGold) {
                goldResults = resultsArr.filter(
                    result => result.category_80_plus === 'gold'
                );
                noFilters = false;
            }
            if (this.state.checkboxStateData.isSilver) {
                silverResults = resultsArr.filter(
                    result => result.category_80_plus === 'silver'
                );
                noFilters = false;
            }
            if (this.state.checkboxStateData.isBronze) {
                bronzeResults = resultsArr.filter(
                    result => result.category_80_plus === 'bronze'
                );
                noFilters = false;
            }
            if (this.state.checkboxStateData.isWhite) {
                whiteResults = resultsArr.filter(
                    result => result.category_80_plus === 'white'
                );
                noFilters = false;
            }
        }

        // If modular checkbox is checked and non modular checkbox is unchecked
        if (this.state.checkboxStateData.isModular && !this.state.checkboxStateData.isNonModular) {
            modularResults = this.state.allResults.filter(
                result => result.modularity === 'modular'
            );
            noFilters = false;
            category80PlusFilter(modularResults)
        }
        // If non modular checkbox is checked and modular checkbox is unchecked
        if (this.state.checkboxStateData.isNonModular && !this.state.checkboxStateData.isModular) {
            nonModularResults = this.state.allResults.filter(
                result => result.modularity === 'non modular'
            );
            noFilters = false;
            category80PlusFilter(nonModularResults)
        }
        // If both modular and non modular checkboxes are checked or unchecked
        if ((this.state.checkboxStateData.isNonModular && this.state.checkboxStateData.isModular) ||
            (!this.state.checkboxStateData.isNonModular && !this.state.checkboxStateData.isModular)) {
            category80PlusFilter(this.state.allResults)
        }

        this.setState({
            modularResults,
            nonModularResults,
            titaniumResults,
            platinumResults,
            goldResults,
            silverResults,
            bronzeResults,
            whiteResults,
            noFilters,
        });
    }

    // Render the correct 80 Plus icon for the results card
    render80PlusIcon = category => {
        if (category === 'titanium') {
            return Titanium80Plus
        } else if (category === 'platinum') {
            return Platinum80Plus
        } else if (category === 'gold') {
            return Gold80Plus
        } else if (category === 'silver') {
            return Silver80Plus
        } else if (category === 'bronze') {
            return Bronze80Plus
        } else {
            return White80Plus
        }
    }

    // Function to open the dialog box
    handleDialogOpen = () => {
        this.setState({filterDialogOpen: true});
    }

    // Function to close the dialog box when the 'X' icon is clicked
    handleDialogClose = () => {
        this.setState({filterDialogOpen: false});
    }

    // Function to close the dialog box and scroll to the top of the screen when the 'Apply' button is clicked
    handleDialogApply = () => {
        this.setState({
                filterDialogOpen: false
            },
            () => window.scrollTo(0, 0)
        );
    }

    // Function to reset the filters
    handleResetFilters = () => {
        this.setState({
                checkboxStateData: {
                    isModular: false,
                    isNonModular: false,
                    isTitanium: false,
                    isPlatinum: false,
                    isGold: false,
                    isSilver: false,
                    isBronze: false,
                    isWhite: false,
                },
            },
            () => {
                this.filterCheckboxResults();
            }
        );
    }

    render() {
        // Destructure state
        const {
            componentQuantities, componentNames, componentWattages, allResults, modularResults,
            nonModularResults, titaniumResults, platinumResults, goldResults, silverResults,
            bronzeResults, whiteResults, isLoading, noFilters,
            checkboxStateData: {
                isTitanium, isPlatinum, isGold, isSilver, isBronze, isWhite,
            },
            filterDialogOpen,
        } = this.state;
        // Destructure props
        const {classes} = this.props;

        const renderResultCards = resultsList => {
            return (
                resultsList.map(result => {
                    return (
                        <Grid item xs={12} sm={6} md={4}>
                            <ResultCard
                                key={result.id}
                                productTitle={result.name}
                                wattage={result.max_wattage}
                                imageUrl={result.image}
                                productDescription={result.description}
                                productLoad={result.load}
                                productModularityIcon={
                                    result.modularity === 'modular' ? Modular : NonModular
                                }
                                productModularityValue={result.modularity}
                                category80PlusIcon={this.render80PlusIcon(result.category_80_plus)}
                                category80PlusValue={result.category_80_plus}
                                productEfficiency={result.efficiency}
                                productLink={result.link}
                            />
                        </Grid>
                    )
                })
            )
        }

        const renderFilterResultsCards = () => {
            let modularResultsCards = [], nonModularResultsCards = [], titaniumResultsCards = [],
                platinumResultsCards = [], goldResultsCards = [], silverResultsCards = [],
                bronzeResultsCards = [], whiteResultsCards = []

            // If only the modular checkbox is checked and no 80 Plus checkboxes are checked
            if (modularResults && !isTitanium && !isPlatinum && !isGold && !isSilver && !isBronze && !isWhite) {
                modularResultsCards = renderResultCards(modularResults)
            }
            // If only the non modular checkbox is checked and no 80 Plus checkboxes are checked
            if (nonModularResults && !isTitanium && !isPlatinum && !isGold && !isSilver && !isBronze && !isWhite) {
                nonModularResultsCards = renderResultCards(nonModularResults)
            }
            if (titaniumResults) {
                titaniumResultsCards = renderResultCards(titaniumResults)
            }
            if (platinumResults) {
                platinumResultsCards = renderResultCards(platinumResults)
            }
            if (goldResults) {
                goldResultsCards = renderResultCards(goldResults)
            }
            if (silverResults) {
                silverResultsCards = renderResultCards(silverResults)
            }
            if (bronzeResults) {
                bronzeResultsCards = renderResultCards(bronzeResults)
            }
            if (whiteResults) {
                whiteResultsCards = renderResultCards(whiteResults)
            }

            return [
                modularResultsCards, nonModularResultsCards, titaniumResultsCards, platinumResultsCards,
                goldResultsCards, silverResultsCards, bronzeResultsCards, whiteResultsCards
            ]
        }

        return (
            <React.Fragment>
                {
                    isLoading ? (
                        <Spinner/>
                    ) : (
                        <React.Fragment>
                            <div className={classes.resultsHeaderContainer}>
                                <ResultsHeader
                                    componentQuantities={componentQuantities}
                                    componentNames={componentNames}
                                    componentWattages={componentWattages}
                                    editConfiguration={this.props.editConfiguration}
                                />
                            </div>
                            <div className={classes.resultsHeaderMobileContainer}>
                                <ResultsHeaderMobile
                                    componentQuantities={componentQuantities}
                                    componentNames={componentNames}
                                    componentWattages={componentWattages}
                                    editConfiguration={this.props.editConfiguration}
                                />
                            </div>
                            <Container maxWidth='xl'>
                                <Grid className={classes.resultsPageWrapper} container spacing={3}>
                                    {/*Filter form dialog - only shown on mobile screens*/}
                                    <Grid className={classes.dialogContainer} item xs={12}>
                                        <Button className={classes.dialogOpenButton} variant="outlined"
                                                onClick={this.handleDialogOpen}>
                                            Refine
                                        </Button>
                                        <Dialog
                                            classes={{
                                                paper: classes.dialogPaper,
                                            }}
                                            open={filterDialogOpen}
                                            onClose={this.handleDialogClose}
                                            fullScreen
                                        >
                                            <FilterForm
                                                handleModularChange={this.handleModularChange}
                                                handleNonModularChange={this.handleNonModularChange}
                                                handleTitaniumChange={this.handleTitaniumChange}
                                                handlePlatinumChange={this.handlePlatinumChange}
                                                handleGoldChange={this.handleGoldChange}
                                                handleSilverChange={this.handleSilverChange}
                                                handleBronzeChange={this.handleBronzeChange}
                                                handleWhiteChange={this.handleWhiteChange}
                                                handleDialogClose={this.handleDialogClose}
                                                handleDialogApply={this.handleDialogApply}
                                                handleResetFilters={this.handleResetFilters}
                                                isModular={this.state.checkboxStateData.isModular}
                                                isNonModular={this.state.checkboxStateData.isNonModular}
                                                isTitanium={this.state.checkboxStateData.isTitanium}
                                                isPlatinum={this.state.checkboxStateData.isPlatinum}
                                                isGold={this.state.checkboxStateData.isGold}
                                                isSilver={this.state.checkboxStateData.isSilver}
                                                isBronze={this.state.checkboxStateData.isBronze}
                                                isWhite={this.state.checkboxStateData.isWhite}
                                            />
                                        </Dialog>
                                    </Grid>
                                    {/*Standard filter form - only shown on sm screens and upwards*/}
                                    <Grid className={classes.filterFormContainer} item xs={12} sm={6} md={4} lg={3}>
                                        <FilterForm
                                            handleModularChange={this.handleModularChange}
                                            handleNonModularChange={this.handleNonModularChange}
                                            handleTitaniumChange={this.handleTitaniumChange}
                                            handlePlatinumChange={this.handlePlatinumChange}
                                            handleGoldChange={this.handleGoldChange}
                                            handleSilverChange={this.handleSilverChange}
                                            handleBronzeChange={this.handleBronzeChange}
                                            handleWhiteChange={this.handleWhiteChange}
                                            handleDialogClose={this.handleDialogClose}
                                            handleDialogApply={this.handleDialogApply}
                                            handleResetFilters={this.handleResetFilters}
                                            isModular={this.state.checkboxStateData.isModular}
                                            isNonModular={this.state.checkboxStateData.isNonModular}
                                            isTitanium={this.state.checkboxStateData.isTitanium}
                                            isPlatinum={this.state.checkboxStateData.isPlatinum}
                                            isGold={this.state.checkboxStateData.isGold}
                                            isSilver={this.state.checkboxStateData.isSilver}
                                            isBronze={this.state.checkboxStateData.isBronze}
                                            isWhite={this.state.checkboxStateData.isWhite}
                                        />
                                    </Grid>
                                    <Grid item xs={12} lg={9}>
                                        <Grid container spacing={3}>
                                            {
                                                noFilters ? (
                                                    allResults.length > 0 ? (
                                                        renderResultCards(allResults)
                                                    ) : (
                                                        <NoResults/>
                                                    )
                                                ) : (
                                                    modularResults.length > 0 || nonModularResults.length > 0 ||
                                                    titaniumResults.length > 0 || platinumResults.length > 0 ||
                                                    goldResults.length > 0 || silverResults.length > 0 ||
                                                    bronzeResults.length > 0 || whiteResults.length > 0 ? (
                                                        renderFilterResultsCards()
                                                    ) : (
                                                        <NoResults/>
                                                    )
                                                )
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Container>
                        </React.Fragment>
                    )
                }
            </React.Fragment>
        )
    }
}

export default withStyles(styles)(Results);
