export const styles = theme => ({
    faqBox: {
        padding: '0 6%',
        [theme.breakpoints.up('lg')]: {
            margin: '45px auto 0',
            width: '1200px',
            padding: 0,
        },
    },
    faqContainer: {
        width: '100%',
        marginBottom: '15px',
    },
    sectionTitle: {
        fontWeight: 'bold',
        marginBottom: '15px',
    },
    root: {
        boxShadow: 'none',
        backgroundColor: '#282c34',
        borderRadius: '5px',
        color: '#FFFFFF',
    },
    questionContainer: {
        backgroundColor: '#303039',
        color: '#A8A8AC',
        border: '1px solid #484850',
        borderRadius: '5px',
    },
    questionTitle: {
        fontSize: '1rem',
    },
    expandIcon: {
        color: '#A8A8AC',
    },
    answerContainer: {
        backgroundColor: '#1E1E24',
        marginTop: '15px',
        border: '1px solid #484850',
        borderRadius: '5px',
    },
    answerContainerTitle: {
        marginTop: '15px',
        marginBottom: '20px',
        fontSize: '18px',
    },
});
