import {makeStyles} from "@material-ui/core/styles";
import React from "react";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Checkbox from "@material-ui/core/Checkbox";
// Import PNG and SVG images
import Modular from '../../assets/modular.svg';
import NonModular from '../../assets/non-modular.svg';
import Titanium80Plus from '../../assets/80-plus-titanium.png';
import Platinum80Plus from '../../assets/80-plus-platinum.png';
import Gold80Plus from '../../assets/80-plus-gold.png';
import Silver80Plus from '../../assets/80-plus-silver.png';
import Bronze80Plus from '../../assets/80-plus-bronze.png';
import White80Plus from '../../assets/80-plus-white.png';
import Button from "@material-ui/core/Button";
// Import FontAwesome
let FontAwesome = require('react-fontawesome');

const useStyles = makeStyles((theme) => ({
    filterFormContainer: {
        backgroundColor: '#33333D',
        borderRadius: '5px',
        padding: '15px',
        textTransform: 'uppercase',
        [theme.breakpoints.down('md')]: {
            height: '100%',
            margin: '30px auto',
            width: 325,
        },
        [theme.breakpoints.down('xs')]: {
            height: '100%',
            margin: '30px auto',
            width: 230,
        },
    },
    closeIconWrapper: {
        textAlign: 'right',
        fontSize: '20px',
        [theme.breakpoints.up('lg')]: {
            display: 'none',
        },
    },
    closeIcon: {
        cursor: 'pointer',
    },
    formSectionHeading: {
        fontSize: '16px',
        fontWeigt: 'bold',
    },
    modularityWrapper: {
        marginBottom: '35px',
        fontWeight: 'bold',
        fontSize: '14px',
        [theme.breakpoints.down('xs')]: {
            marginBottom: '20px',
        },
        [theme.breakpoints.down(395)]: {
            fontSize: 'small',
        },
    },
    modularityIcon: {
        width: '20px',
        verticalAlign: 'middle',
        margin: '10px 15px',
    },
    eightyPlusWrapper: {
        marginBottom: '5px',
        fontWeight: 'bold',
        fontSize: '14px',
        [theme.breakpoints.down(395)]: {
            fontSize: 'small',
        },
    },
    eightyPlusIcon: {
        width: '20px',
        verticalAlign: 'middle',
        margin: '10px 15px',
    },
    linebreak: {
        width: '100%',
        marginTop: '10px',
        marginBottom: '20px',
    },
    root: {
        color: '#ADADB1',
        padding: '9px 0',
        "&$checked": {
            color: '#DB3236',
        }
    },
    checked: {},
    buttonContainer: {
        margin: '10px 0',
        textAlign: 'center',
    },
    resetButton: {
        border: '1px solid #FFFFFF',
        color: '#FFFFFF',
        marginRight: '15px',
        fontWeight: 'bold',
        width: '40%',
        "&:hover": {
            border: '1px solid #A8A8AC',
        },
        [theme.breakpoints.up('lg')]: {
            marginRight: '0',
        },
    },
    submitButton: {
        backgroundColor: '#DB3236',
        color: '#FFFFFF',
        fontWeight: 'bold',
        width: '40%',
        "&:hover": {
            backgroundColor: '#af2e31',
        },
        [theme.breakpoints.up('lg')]: {
            display: 'none'
        },
    },
}));

const FilterForm = ({
                        handleModularChange, handleNonModularChange, handleTitaniumChange, handlePlatinumChange,
                        handleGoldChange, handleSilverChange, handleBronzeChange, handleWhiteChange, handleDialogClose,
                        handleResetFilters, isModular, isNonModular, isTitanium, isPlatinum, isGold, isSilver,
                        isBronze, isWhite, handleDialogApply
                    }) => {
    // Get the classes from the useStyles function
    const classes = useStyles();

    return (
        <Box className={classes.filterFormContainer}>
            <div className={classes.closeIconWrapper}>
                <FontAwesome name="times" className={classes.closeIcon} onClick={handleDialogClose}/>
            </div>
            <div className={classes.modularityWrapper}>
                <Typography className={classes.formSectionHeading} variant="h6" component="h6">
                    Modularity
                </Typography>
                <hr className={classes.linebreak}/>
                <div>
                    <Checkbox
                        classes={{
                            root: classes.root,
                            checked: classes.checked
                        }}
                        checked={isModular}
                        onChange={handleModularChange}
                        inputProps={{'aria-label': 'Modular checkbox'}}
                    />
                    <img className={classes.modularityIcon} src={Modular} alt="Modular Icon"/>
                    <span>Modular</span>
                </div>
                <div>
                    <Checkbox
                        classes={{
                            root: classes.root,
                            checked: classes.checked
                        }}
                        checked={isNonModular}
                        onChange={handleNonModularChange}
                        inputProps={{'aria-label': 'Non-modular checkbox'}}
                    />
                    <img className={classes.modularityIcon} src={NonModular} alt="Non Modular Icon"/>
                    <span>Non Modular</span>
                </div>
            </div>
            <div className={classes.eightyPlusWrapper}>
                <Typography className={classes.formSectionHeading} variant="h6" component="h6">
                    80 Plus
                </Typography>
                <hr className={classes.linebreak}/>
                <div>
                    <Checkbox
                        classes={{
                            root: classes.root,
                            checked: classes.checked
                        }}
                        checked={isTitanium}
                        onChange={handleTitaniumChange}
                        inputProps={{'aria-label': 'Titanium 80 Plus checkbox'}}
                    />
                    <img className={classes.eightyPlusIcon} src={Titanium80Plus} alt="Titanium 80 Plus Icon"/>
                    <span>Titanium</span>
                </div>
                <div>
                    <Checkbox
                        classes={{
                            root: classes.root,
                            checked: classes.checked
                        }}
                        checked={isPlatinum}
                        onChange={handlePlatinumChange}
                        inputProps={{'aria-label': 'Platinum 80 Plus checkbox'}}
                    />
                    <img className={classes.eightyPlusIcon} src={Platinum80Plus} alt="Platinum 80 Plus Icon"/>
                    <span>Platinum</span>
                </div>
                <div>
                    <Checkbox
                        classes={{
                            root: classes.root,
                            checked: classes.checked
                        }}
                        checked={isGold}
                        onChange={handleGoldChange}
                        inputProps={{'aria-label': 'Gold 80 Plus checkbox'}}
                    />
                    <img className={classes.eightyPlusIcon} src={Gold80Plus} alt="Gold 80 Plus Icon"/>
                    <span>Gold</span>
                </div>
                <div>
                    <Checkbox
                        classes={{
                            root: classes.root,
                            checked: classes.checked
                        }}
                        checked={isSilver}
                        onChange={handleSilverChange}
                        inputProps={{'aria-label': 'Silver 80 Plus checkbox'}}
                    />
                    <img className={classes.eightyPlusIcon} src={Silver80Plus} alt="Silver 80 Plus Icon"/>
                    <span>Silver</span>
                </div>
                <div>
                    <Checkbox
                        classes={{
                            root: classes.root,
                            checked: classes.checked
                        }}
                        checked={isBronze}
                        onChange={handleBronzeChange}
                        inputProps={{'aria-label': 'Bronze 80 Plus checkbox'}}
                    />
                    <img className={classes.eightyPlusIcon} src={Bronze80Plus} alt="Bronze 80 Plus Icon"/>
                    <span>Bronze</span>
                </div>
                <div>
                    <Checkbox
                        classes={{
                            root: classes.root,
                            checked: classes.checked
                        }}
                        checked={isWhite}
                        onChange={handleWhiteChange}
                        inputProps={{'aria-label': 'White 80 Plus checkbox'}}
                    />
                    <img className={classes.eightyPlusIcon} src={White80Plus} alt="White 80 Plus Icon"/>
                    <span>White</span>
                </div>
                <div className={classes.buttonContainer}>
                    <Button className={classes.resetButton} variant="outlined" onClick={handleResetFilters}>
                        Reset
                    </Button>
                    <Button className={classes.submitButton}
                            variant="contained"
                            onClick={handleDialogApply}
                            disableElevation>
                        Apply
                    </Button>
                </div>
            </div>
        </Box>
    )
};

export default FilterForm;
