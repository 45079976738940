import {makeStyles} from "@material-ui/core/styles";
import React from "react";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
// Import SVG icons
import CPULogo from '../../assets/cpu.svg';
import MBLogo from '../../assets/motherboard.svg';
import GPULogo from '../../assets/gpu.svg';
import RAMLogo from '../../assets/ram.svg';
import SSDLogo from '../../assets/ssd.svg';
import HDDLogo from '../../assets/hdd.svg';
import ODDLogo from '../../assets/cd.svg';
// Import FontAwesome
let FontAwesome = require('react-fontawesome');

const useStyles = makeStyles((theme) => ({
    configWrapper: {
        backgroundColor: '#1E1E24',
        marginBottom: '60px',
        paddingTop: '40px',
        paddingBottom: '40px',
        [theme.breakpoints.down('xs')]: {
            marginBottom: '25px',
        },
    },
    configContainer: {
        paddingLeft: '10%',
        paddingRight: '10%',
    },
    headerTitle: {
        fontWeight: 'bold',
    },
    lineBreak: {
        width: '100%',
        margin: '15px auto',
    },
    svgIcon: {
        display: 'inline',
        width: '30px',
        marginRight: '10px'
    },
    formSubtitle: {
        fontWeight: 'normal',
        display: 'inline',
        position: 'absolute',
        transform: 'translateY(5px)',
        fontSize: '14px',
        [theme.breakpoints.up('md')]: {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            maxWidth: '15%',
            transform: 'translateY(3px)',
            fontSize: '1rem',
        }
    },
    componentType: {
        color: '#DB3236',
        marginRight: '10px',
        textTransform: 'uppercase',
    },
    editButton: {
        border: '1px solid #FFFFFF',
        color: '#FFFFFF',
        marginRight: '15px',
        fontWeight: 'bold',
        [theme.breakpoints.down('sm')]: {
            border: 'none',
        },
        "&:hover": {
            border: '1px solid #A8A8AC',
            [theme.breakpoints.down('sm')]: {
                border: 'none',
                color: '#A8A8AC',
            },
        },
    },
    editButtonText: {
        [theme.breakpoints.down('sm')]: {
            textDecoration: 'underline',
            "&:hover": {
                textDecoration: 'underline',
            }
        },
    },
    chevronIcon: {
        marginRight: '10px',
        [theme.breakpoints.up('md')]: {
            visibility: 'hidden',
        },
    },
    penIcon: {
        marginLeft: '10px',
        color: '#A5A5A7',
        [theme.breakpoints.up('md')]: {
            visibility: 'hidden',
        },
    },
}));

const ResultsHeader = ({componentQuantities, componentNames, editConfiguration}) => {
    // Get the classes from the useStyles function
    const classes = useStyles();

    return (
        <Box className={classes.configWrapper}>
            <Container className={classes.configContainer} maxWidth='xl' disableGutters>
                <Grid container spacing={3}>
                    <Typography className={classes.headerTitle} variant="h5" component="h1">
                        Your Configuration
                    </Typography>
                    <hr className={classes.lineBreak}/>
                    <Grid item xs={12} md={3}>
                        <img className={classes.svgIcon} src={CPULogo} alt="CPU Icon"/>
                        <Typography className={classes.formSubtitle} variant="h6" component="h6">
                            <span className={classes.componentType}>CPU</span>
                            <span>
                                {componentNames.cpuChildName ? componentNames.cpuChildName : "None"}
                            </span>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <img className={classes.svgIcon} src={MBLogo} alt="Motherboard Icon"/>
                        <Typography className={classes.formSubtitle} variant="h6" component="h6">
                            <span className={classes.componentType}>MOBO</span>
                            <span>
                                    {componentNames.motherboardName ? componentNames.motherboardName : "None"}
                            </span>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <img className={classes.svgIcon} src={GPULogo} alt="GPU Icon"/>
                        <Typography className={classes.formSubtitle} variant="h6" component="h6">
                            <span className={classes.componentType}>GPU</span>
                            <span>
                                {componentNames.gpuChildName ? componentNames.gpuChildName : "None"}
                                {componentNames.gpuChildName ?
                                    ` x ${componentQuantities.gpuChildQuantity}` : ''}
                            </span>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <img className={classes.svgIcon} src={RAMLogo} alt="RAM Icon"/>
                        <Typography className={classes.formSubtitle} variant="h6" component="h6">
                            <span className={classes.componentType}>RAM</span>
                            <span>
                                {componentNames.ramComponentName ?
                                    `${componentNames.ramComponentName} x ${componentQuantities.ramQuantity}` : "None"}
                            </span>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <img className={classes.svgIcon} src={SSDLogo} alt="SSD Icon"/>
                        <Typography className={classes.formSubtitle} variant="h6" component="h6">
                            <span className={classes.componentType}>SSD</span>
                            <span>
                                {componentNames.ssdComponentName ? componentNames.ssdComponentName : "None"}
                                {componentNames.ssdComponentName && componentNames.ssdComponentName !== 'Not Installed' ?
                                    ` x ${componentQuantities.ssdQuantity}` : ''}
                            </span>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <img className={classes.svgIcon} src={HDDLogo} alt="HDD Icon"/>
                        <Typography className={classes.formSubtitle} variant="h6" component="h6">
                            <span className={classes.componentType}>HDD</span>
                            <span>
                                {componentNames.hddComponentName ? componentNames.hddComponentName : "None"}
                                {componentNames.hddComponentName && componentNames.hddComponentName !== 'Not Installed' ?
                                    ` x ${componentQuantities.hddQuantity}` : ''}
                            </span>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <img className={classes.svgIcon} src={ODDLogo} alt="CPU Icon"/>
                        <Typography className={classes.formSubtitle} variant="h6" component="h6">
                            <span className={classes.componentType}>OPTICAL DRIVE</span>
                            <span>
                                {componentNames.oddComponentName ? componentNames.oddComponentName : "None"}
                            </span>
                        </Typography>
                    </Grid>
                    <hr className={classes.lineBreak}/>
                    <Button
                        className={classes.editButton}
                        variant="outlined"
                        onClick={editConfiguration}
                    >
                        <FontAwesome className={classes.chevronIcon} name='chevron-left'/>
                        <span className={classes.editButtonText}>Edit Configuration</span>
                        <FontAwesome className={classes.penIcon} name='pen'/>
                    </Button>
                </Grid>
            </Container>
        </Box>
    )
}

export default ResultsHeader;
