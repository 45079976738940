// Import styles
import {makeStyles} from "@material-ui/core/styles";
import React from "react";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
// Import FontAwesome
let FontAwesome = require('react-fontawesome');

const useStyles = makeStyles((theme) => ({
    calculatorContainer: {
        border: 'solid 1px #484850',
        borderRadius: '5px',
        backgroundColor: '#1E1E24',
        marginTop: '31px',
        padding: '10%',
        [theme.breakpoints.down('sm')]: {
            marginTop: 0,
        },
    },
    calculatorTitle: {
        fontWeight: 'bold',
        marginBottom: '15px',
    },
    calculatorWattage: {
        fontWeight: 'bold',
        color: '#DB3236',
        marginBottom: '15px',
    },
    resetButton: {
        border: '1px solid #FFFFFF',
        color: '#FFFFFF',
        marginBottom: 15,
        fontWeight: 'bold',
        maxWidth: '50%',
        display: 'block',
        "&:hover": {
            border: '1px solid #A8A8AC',
        },
    },
    submitButton: {
        backgroundColor: '#DB3236',
        color: '#FFFFFF',
        fontWeight: 'bold',
        maxWidth: '90%',
        "&:hover": {
            backgroundColor: '#af2e31',
        },
    },
    calculatorIcon: {
        marginLeft: '10px',
    },
}));

const Calculator = ({totalWattage, resetForm, submitForm}) => {
    // Get the classes from the useStyles function
    const classes = useStyles();

    return (
        <div>
            <Box align="center" className={classes.calculatorContainer}>
                <Typography className={classes.calculatorTitle} variant="h6" component="h6">
                    Your recommended PSU wattage is:
                </Typography>
                <Typography className={classes.calculatorWattage} variant="h2" component="h1">
                    {totalWattage} Watts
                </Typography>
                <Button className={classes.resetButton} variant="outlined" onClick={resetForm}>
                    Reset
                </Button>
                <Button className={classes.submitButton} variant="contained" onClick={submitForm} disableElevation>
                    Show Results
                    <FontAwesome className={classes.calculatorIcon} name='chevron-right'/>
                </Button>
            </Box>
        </div>
    );
};

export default Calculator;
