import React, {Component} from 'react';
import './App.css';
import SelectForm from "./components/select-form/select-form";
import FAQ from "./components/faqs/faqs";
import Spinner from "./components/spinner/spinner";

class App extends Component {

    constructor(props) {
        super(props);

        this.state = {
            components: [],
            cpuParents: [],
            cpuChildrenAmd: [],
            cpuChildrenIntel: [],
            motherboards: [],
            gpuParents: [],
            gpuChildrenAmd: [],
            gpuChildrenNvidia: [],
            ramList: [],
            ssdList: [],
            hddList: [],
            oddList: [],
            isLoading: true,
        }
    }

    componentDidMount() {
        this.getComponents();
    }

    // Get the full list of all components, and filter them down to the required lists
    getComponents() {
        fetch('https://api.calculator.bgfg.co.uk/calculator/components/', {
            method: 'GET',
            headers: {
                'Authorization': `Token ${process.env.REACT_APP_TOKEN}`
            }
        }).then(res => res.json())
            .then(res => this.setState({components: res}))
            // Call the functions that filter down to the relevant lists
            .then(res => {
                this.filterCpuParents();
                this.filterCpuChildrenAmd();
                this.filterCpuChildrenIntel();
                this.filterMotherboards();
                this.filterGpuParents();
                this.filterGpuChildrenAmd();
                this.filterGpuChildrenNvidia();
                this.filterRamList();
                this.filterSsdList();
                this.filterHddList();
                this.filterOddList();
            })
            .then(res => this.setState({isLoading: false}))
            // ------------------------------ Catch and log any errors ------------------------------
            .catch(error => console.log(error))
    }

    // Get filtered list of CPU parents
    filterCpuParents() {
        const cpuParents = this.state.components.filter(
            cpuParent => cpuParent.category === 'cpu' && cpuParent.parent === null
        );
        this.setState({cpuParents});
    }

    // Get filtered list of children of AMD CPU parent
    filterCpuChildrenAmd() {
        const cpuChildrenAmd = this.state.components.filter(
            cpuChildAmd =>
                cpuChildAmd.parent && cpuChildAmd.parent.name === 'AMD' && cpuChildAmd.parent.category === 'cpu'
        );
        this.setState({cpuChildrenAmd});
    }

    // Get filtered list of children of Intel CPU parent
    filterCpuChildrenIntel() {
        const cpuChildrenIntel = this.state.components.filter(
            cpuChildIntel =>
                cpuChildIntel.parent &&
                cpuChildIntel.parent.name === 'Intel' &&
                cpuChildIntel.parent.category === 'cpu'
        );
        this.setState({cpuChildrenIntel});
    }

    // Get filtered list of Motherboards
    filterMotherboards() {
        const motherboards = this.state.components.filter(
            motherboard => motherboard.category === 'motherboard'
        );
        this.setState({motherboards});
    }

    // Get filtered list of GPU parents
    filterGpuParents() {
        const gpuParents = this.state.components.filter(
            gpuParent => gpuParent.category === 'gpu' && gpuParent.parent === null
        );
        this.setState({gpuParents});
    }

    // Get filtered list of children of AMD GPU parent
    filterGpuChildrenAmd() {
        const gpuChildrenAmd = this.state.components.filter(
            gpuChildAmd =>
                gpuChildAmd.parent && gpuChildAmd.parent.name === 'AMD' && gpuChildAmd.parent.category === 'gpu'
        );
        this.setState({gpuChildrenAmd});
    }

    // Get filtered list of children of NVIDIA GPU parent
    filterGpuChildrenNvidia() {
        const gpuChildrenNvidia = this.state.components.filter(
            gpuChildNvidia =>
                gpuChildNvidia.parent &&
                gpuChildNvidia.parent.name === 'NVIDIA' &&
                gpuChildNvidia.parent.category === 'gpu'
        );
        this.setState({gpuChildrenNvidia});
    }

    // Get filtered list of RAM components
    filterRamList() {
        const ramList = this.state.components.filter(
            ram => ram.category === 'ram'
        );
        this.setState({ramList});
    }

    // Get filtered list of SSD components
    filterSsdList() {
        const ssdList = this.state.components.filter(
            ssd => ssd.category === 'ssd'
        );
        this.setState({ssdList});
    }

    // Get filtered list of HDD components
    filterHddList() {
        const hddList = this.state.components.filter(
            hdd => hdd.category === 'hdd'
        );
        this.setState({hddList});
    }

    // Get filtered list of Optical Drive components
    filterOddList() {
        const oddList = this.state.components.filter(
            odd => odd.category === 'optical_drive'
        );
        this.setState({oddList});
    }

    render() {
        return (
            <div className="app-container">
                {
                    this.state.isLoading ? (
                        <Spinner/>
                    ) : (
                        <React.Fragment>
                            <SelectForm
                                cpuParents={this.state.cpuParents}
                                cpuChildrenAmd={this.state.cpuChildrenAmd}
                                cpuChildrenIntel={this.state.cpuChildrenIntel}
                                motherboards={this.state.motherboards}
                                gpuParents={this.state.gpuParents}
                                gpuChildrenAmd={this.state.gpuChildrenAmd}
                                gpuChildrenNvidia={this.state.gpuChildrenNvidia}
                                ramList={this.state.ramList}
                                ssdList={this.state.ssdList}
                                hddList={this.state.hddList}
                                oddList={this.state.oddList}
                            />
                            {/*
                                Commented out component as not needed for now
                                <hr/>
                                <FAQ/>
                            */}
                        </React.Fragment>
                    )
                }
            </div>
        );
    }
}

export default App;
