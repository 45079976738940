// Import custom styles
import {styles} from './faqs-styles';
import React, {Component} from "react";
import {withStyles} from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Box from "@material-ui/core/Box";

class FAQ extends Component {
    constructor(props) {
        super(props);

        this.state = {
            faqsList: []
        }
    }

    componentDidMount() {
        // Get the full list of all FAQs
        fetch('https://api.calculator.bgfg.co.uk/calculator/faqs/', {
            method: 'GET',
            headers: {
                'Authorization': `Token ${process.env.REACT_APP_TOKEN}`
            }
        }).then(res => res.json())
            .then(res => this.setState({faqsList: res}))
            .catch(error => console.log(error))
    }

    render() {
        // Destructure the classes from the styles
        const {classes} = this.props;

        return (
            <Box className={classes.faqBox}>
                <div>
                    <Typography className={classes.sectionTitle} variant="h5" component="h1">
                        FAQ
                    </Typography>
                </div>

                {
                    this.state.faqsList.map(faq => (
                        <div className={classes.faqContainer}>
                            <ExpansionPanel className={classes.root}>
                                <ExpansionPanelSummary
                                    className={classes.questionContainer}
                                    expandIcon={<ExpandMoreIcon className={classes.expandIcon}/>}
                                    aria-controls={faq.id}
                                    key={faq.id}
                                    id={faq.id}
                                >
                                    <Typography className={classes.questionTitle} variant="h6" component="h6">
                                        {faq.question}
                                    </Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails className={classes.answerContainer}>
                                    <Typography component="p">
                                        <div className={classes.answerContainerTitle}>
                                            {faq.question}
                                        </div>
                                        <div>
                                            {faq.answer}
                                        </div>
                                    </Typography>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        </div>
                    ))
                }
            </Box>

        )
    }
}

// Wrap the SelectForm component with the withStyles HOC to give is access to the styles
export default withStyles(styles)(FAQ);
