export const styles = theme => ({
    resultsPageWrapper: {
        padding: '0 35px',
        [theme.breakpoints.down('lg')]: {
            padding: '0 10px',
        },
    },
    resultsHeaderContainer: {
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        },
    },
    resultsHeaderMobileContainer: {
        [theme.breakpoints.up('md')]: {
            display: 'none'
        },
    },
    filterFormContainer: {
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
        [theme.breakpoints.up('lg')]: {
            maxWidth: '20%',
        },
    },
    dialogContainer: {
        position: 'sticky',
        top: '0',
        backgroundColor: '#27272F',
        zIndex: 50,
        [theme.breakpoints.up('lg')]: {
            display: 'none'
        },
    },
    dialogOpenButton: {
        border: '1px solid #FFFFFF',
        color: '#FFFFFF',
        fontWeight: 'bold',
        width: '100%',
        backgroundColor: '#1E1E24',
        "&:hover": {
            backgroundColor: '#1E1E24',
            border: '1px solid #A8A8AC',
        },
    },
    dialogPaper: {
        backgroundColor: '#1E1E24',
        padding: '30px',
        color: '#FFFFFF',
    },
});
