import {makeStyles, withStyles} from "@material-ui/core/styles";
import React from "react";
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
import Button from "@material-ui/core/Button";
import Link from '@material-ui/core/Link'
import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";
// Import FontAwesome
let FontAwesome = require('react-fontawesome');

// Custom styles for the main results card
const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: '#1E1E24',
        border: '1px solid #484850',
        color: '#FFFFFF',
        boxShadow: 'none',
    },
    headerRoot: {
        backgroundColor: '#1E1E24',
        borderLeft: '5px solid #DB3236',
        borderBottom: '1px solid #484850',
        minHeight: 120,
        [theme.breakpoints.down('md')]: {
            minHeight: 130,
        },
        [theme.breakpoints.down('xs')]: {
            minHeight: 'unset',
        },
    },
    title: {
        fontWeight: 'bold',
        textTransform: 'uppercase',
        [theme.breakpoints.down('lg')]: {
            fontSize: '1.3rem',
        },
    },
    subheader: {
        color: '#FFFFFF',
    },
    mediaRoot: {
        borderBottom: '1px solid #484850',
        padding: '10px 0',
        textAlign: 'center',
        backgroundColor: '#FFFFFF',
    },
    image: {
        height: '200px',
        width: '200px',
        borderRadius: '5px',
        // objectFit: 'cover',
    },
    cardDescription: {
        borderBottom: '1px solid #484850',
        color: 'rgba(255, 255, 255, 0.8)',
        minHeight: 160,
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('xs')]: {
            minHeight: 'unset',
        },
    },
    progressWrapper: {
        borderBottom: '1px solid #484850',
    },
    progressBar: {
        [theme.breakpoints.between(1280, 1425)]: {
            margin: 'auto',
            width: '90%',
        },
        [theme.breakpoints.between(960, 1090)]: {
            margin: 'auto',
            width: '90%',
        },
        [theme.breakpoints.between(600, 745)]: {
            margin: 'auto',
            width: '75%',
        },
        [theme.breakpoints.down(420)]: {
            margin: 'auto',
            width: '85%',
        },
    },
    alignLeft: {
        textAlign: 'left',
    },
    alignRight: {
        textAlign: 'right',
    },
    alignCenter: {
        textAlign: 'center',
    },
    progressTitle: {
        fontWeight: 'Bold',
    },
    progressLoadPercentage: {
        fontWeight: 'Bold',
        color: '#1EB980',
    },
    progressEfficiencyPercentage: {
        fontWeight: 'Bold',
        color: '#72DEFF',
    },
    modularity80PlusWrapper: {
        borderBottom: '1px solid #484850',
        textAlign: 'center',
    },
    modularityIcon: {
        width: '20px',
        verticalAlign: 'middle',
        marginRight: '10px',
        transform: 'translateY(5px)',
    },
    modularityText: {
        fontWeight: 'bold',
        transform: 'translateY(7px)',
        display: 'inline-block',
        fontSize: '14px',
    },
    eightyPlusIcon: {
        width: '20px',
        verticalAlign: 'middle',
        marginRight: '10px',
    },
    eightyPlusText: {
        fontWeight: 'bold',
        display: 'inline-block',
        transform: 'translateY(2px)',
        fontSize: '14px',
    },
    linkButtonWrapper: {
        justifyContent: 'center',
        padding: '20px'
    },
    linkButton: {
        backgroundColor: '#DB3236',
        color: '#FFFFFF',
        height: '50px',
        width: '165px',
        fontWeight: 'bold',
        "&:hover": {
            backgroundColor: '#af2e31',
        },
    },
    link: {
        color: '#FFFFFF',
        textDecoration: 'none',
        "&:hover": {
            color: '#FFFFFF',
            textDecoration: 'none',
        }
    },
    cartIcon: {
        marginLeft: '10px',
    },
}));

// Custom styles for the load progress bar
const LoadProgressBar = withStyles({
    root: {
        height: 10,
        backgroundColor: '#33333D',
        borderRadius: 20,
        marginRight: '10px',
        transform: 'translateY(4px)',
    },
    bar: {
        backgroundColor: '#1EB980',
    },
})(LinearProgress);

// Custom styles for the efficiency progress bar
const EfficiencyProgressBar = withStyles({
    root: {
        height: 10,
        backgroundColor: '#33333D',
        borderRadius: 20,
        marginRight: '10px',
        transform: 'translateY(4px)',
    },
    bar: {
        backgroundColor: '#72DEFF',
    },
})(LinearProgress);

const ResultCard = ({
                        productTitle, wattage, imageUrl, productDescription, productLoad,
                        productEfficiency, productModularityIcon, productModularityValue,
                        category80PlusIcon, category80PlusValue, productLink
                    }) => {
    // Get the classes from the useStyles function
    const classes = useStyles();

    return (
        <React.Fragment>
            <Card
                classes={{
                    root: classes.root
                }}
            >
                <CardHeader
                    classes={{
                        root: classes.headerRoot,
                        title: classes.title,
                        subheader: classes.subheader,
                    }}
                    title={productTitle}
                    subheader={wattage}
                />
                <CardMedia
                    classes={{
                        root: classes.mediaRoot,
                    }}
                    title={productTitle}
                >
                    <img className={classes.image} src={imageUrl} alt={productTitle}/>
                </CardMedia>
                <CardContent
                    classes={{
                        root: classes.cardDescription
                    }}
                >
                    <Typography variant="body2" component="p">
                        {productDescription}
                    </Typography>
                </CardContent>
                <CardContent className={classes.progressWrapper}>
                    <Typography variant="body2" component="p">
                        <Grid container>
                            <Grid className={classes.alignLeft} item xs={3}>
                                <span className={classes.progressTitle}>Load</span>
                            </Grid>
                            <Grid className={classes.alignCenter} item xs={8}>
                                <LoadProgressBar
                                    className={classes.progressBar}
                                    variant="determinate"
                                    color="secondary"
                                    value={productLoad}
                                />
                            </Grid>
                            <Grid className={classes.alignRight} item xs={1}>
                                <span className={classes.progressLoadPercentage}>{productLoad}&#37;</span>
                            </Grid>
                        </Grid>
                    </Typography>
                    <Typography variant="body2" component="p">
                        <Grid container>
                            <Grid className={classes.alignLeft} item xs={3}>
                                <span className={classes.progressTitle}>Efficiency</span>
                            </Grid>
                            <Grid className={classes.alignCenter} item xs={8}>
                                <EfficiencyProgressBar
                                    className={classes.progressBar}
                                    variant="determinate"
                                    color="secondary"
                                    value={productEfficiency}
                                />
                            </Grid>
                            <Grid className={classes.alignRight} item xs={1}>
                                <span className={classes.progressEfficiencyPercentage}>{productEfficiency}&#37;</span>
                            </Grid>
                        </Grid>
                    </Typography>
                </CardContent>
                <CardContent classes={{root: classes.modularity80PlusWrapper}}>
                    <Grid container>
                        <Grid item xs={6}>
                            <img className={classes.modularityIcon} src={productModularityIcon} alt="Modular Icon"/>
                            <span className={classes.modularityText}>{productModularityValue.toUpperCase()}</span>
                        </Grid>
                        <Grid item xs={6}>
                            <img className={classes.eightyPlusIcon} src={category80PlusIcon}
                                 alt="Titanium 80 Plus Icon"/>
                            <span className={classes.eightyPlusText}>{category80PlusValue.toUpperCase()}</span>
                        </Grid>
                    </Grid>
                </CardContent>
                <CardActions classes={{root: classes.linkButtonWrapper}}>
                    <Button
                        className={classes.linkButton}
                        variant="contained"
                        disableElevation
                    >
                        <Link className={classes.link} href={productLink} target="_blank">Check Price</Link>
                        <FontAwesome className={classes.cartIcon} name='shopping-cart'/>
                    </Button>
                </CardActions>
            </Card>
        </React.Fragment>
    );
}

export default ResultCard;
