import {makeStyles} from "@material-ui/core/styles";
import React from "react";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";

const useStyles = makeStyles((theme) => ({
    header: {
        backgroundColor: '#33333D',
        color: '#FFFFFF',
        padding: '40px',
    },
    heading: {
        textTransform: 'uppercase',
        marginBottom: '10px',
        fontWeight: 'bold',
    },
    paragraph: {
        opacity: '0.8',
    }
}));

const Header = () => {
    // Get the classes from the useStyles function
    const classes = useStyles();

    return (
        <Box className={classes.header} align="center">
            <Container fixed>
                <Typography className={classes.heading} variant="h4" component="h1">
                    Power Supply Calculator
                </Typography>
                <Typography className={classes.paragraph} component="p">
                    How much wattage do you need for your PC build?
                    <br/>
                    This tool will help you select a suitable power supply unit for your system.
                </Typography>
            </Container>
        </Box>
    )
};

export default Header;
