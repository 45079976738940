export const styles = theme => ({
    // Autocomplete option styles
    rootHasNoVal: {
        color: '#FFFFFF',
        backgroundColor: '#1E1E24',
        borderRadius: '5px',
        "&:hover": {
            backgroundColor: '#1E1E24',
            borderRadius: '5px',
        },
        "&:focus-within": {
            backgroundColor: '#1E1E24',
            borderRadius: '5px',
            opacity: 1,
        },
        "& .MuiOutlinedInput-notchedOutline": {
            border: '1px solid #484850',
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
            border: '1px solid #484850',
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: '1px solid #484850',
            borderRadius: '5px 5px 0 0',
        },
        "& .MuiInputLabel-outlined": {
            color: '#FFFFFF',
            [theme.breakpoints.down(488)]: {
                fontSize: 'small',
            },
        },
        "& .Mui-disabled": {
            backgroundColor: '#303039',
            opacity: 0.6,
            "&:hover": {
                backgroundColor: '#303039',
                opacity: 0.6,
            },
        },
        "& .Mui-disabled .MuiOutlinedInput-notchedOutline": {
            border: '1px solid #484850',
        },
    },
    rootHasVal: {
        color: '#FFFFFF',
        backgroundColor: '#1E1E24',
        borderRadius: '5px',
        "&:hover": {
            backgroundColor: '#1E1E24',
            borderRadius: '5px',
        },
        "&:focus-within": {
            backgroundColor: '#1E1E24',
            borderRadius: '5px',
        },
        "& .MuiOutlinedInput-notchedOutline": {
            border: '1px solid #484850',
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
            border: '1px solid #484850',
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: '1px solid #484850',
            borderRadius: '5px 5px 0 0',
            outline: 'none',
        },
        "& .MuiInputLabel-outlined": {
            color: '#FFFFFF',
        },
        "& .Mui-disabled": {
            opacity: 0.6,
        },
        "& .Mui-disabled .MuiOutlinedInput-notchedOutline": {
            border: '1px solid #484850',
        },
    },
    paper: {
        margin: 0,
        borderRadius: '0 0 5px 5px',
        border: '1px solid #484850',
    },
    listbox: {
        backgroundColor: '#1E1E24',
        color: '#FFFFFF',
    },
    option: {
        "&:hover": {
            backgroundColor: '#303039',
        },
    },
    noOptions: {
        backgroundColor: '#1E1E24',
        color: '#FFFFFF',
    },
    inputRoot: {
        color: '#FFFFFF',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },
    input: {
        "&:focus": {
            backgroundColor: '#BCBCBE',
            color: '#000000',
            borderRadius: '5px',
        }
    },
    clearIndicator: {
        color: '#FFFFFF',
    },
    popupIndicator: {
        color: '#FFFFFF',
        [theme.breakpoints.down('xs')]: {
            padding: '2px 0',
            marginRight: '-6px',
        },
    },
    // Quantity dropdown styles
    quantityRootHasNoVal: {
        color: '#FFFFFF',
        backgroundColor: '#1E1E24',
        borderRadius: '5px',
        "&:hover": {
            backgroundColor: '#1E1E24',
            borderRadius: '5px',
        },
        "&:focus-within": {
            backgroundColor: '#1E1E24',
            borderRadius: '5px',
            opacity: 1,
        },
        "& .MuiOutlinedInput-notchedOutline": {
            border: '1px solid #484850',
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
            border: '1px solid #484850',
        },
        "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: '1px solid #484850',
            borderRadius: '5px',
            outline: 'none',
        },
        "& .Mui-disabled": {
            backgroundColor: '#303039',
            color: '#FFFFFF',
            opacity: 0.6,
            "& .MuiSelect-icon": {
                backgroundColor: '#303039',
                color: '#26272e',
                opacity: 0.6,
            },
            "&:hover": {
                backgroundColor: '#303039',
            },
        },
        "& .Mui-disabled .MuiOutlinedInput-notchedOutline": {
            border: '1px solid #484850',
        },
    },
    quantityRootHasVal: {
        color: '#FFFFFF',
        backgroundColor: '#1E1E24',
        borderRadius: '5px',
        "&:hover": {
            backgroundColor: '#1E1E24',
            borderRadius: '5px',
        },
        "&:focus-within": {
            backgroundColor: '#1E1E24',
            borderRadius: '5px',
        },
        "& .MuiOutlinedInput-notchedOutline": {
            border: '1px solid #484850',
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
            border: '1px solid #484850',
        },
        "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: '1px solid #484850',
            borderRadius: '5px',
            outline: 'none',
        },
    },
    selectRoot: {
        color: '#FFFFFF',
    },
    icon: {
        color: '#FFFFFF',
        [theme.breakpoints.down('xs')]: {
            right: 0,
        },
    },
    selectPaper: {
        backgroundColor: '#1E1E24',
        border: '1px solid #484850',
        borderRadius: '5px',
        color: '#FFFFFF',
        "& li:hover": {
            backgroundColor: '#303039',
        },
    },
    selectLabelRoot: {
        color: '#FFFFFF',
        [theme.breakpoints.down(488)]: {
            fontSize: 'small',
        },
        "&.Mui-focused": {
            color: '#FFFFFF',
        },
    },
    formHelper: {
        position: 'absolute',
        color: '#f44336',
        left: '13px',
        top: '115px',
    },
    componentContainer: {
        marginTop: '45px',
        marginLeft: 0,
        marginRight: 0,
        padding: '0 2%',
        width: '100%',
        [theme.breakpoints.up('lg')]: {
            margin: '45px auto 0',
            width: '1200px',
            padding: 0,
        },
    },
    formTitle: {
        fontWeight: 'bold',
        marginBottom: '15px',
    },
    formSection: {
        position: 'relative',
        marginTop: '10px',
        marginBottom: '30px',
    },
    finalFormSection: {
        position: 'relative',
        marginTop: '10px',
        marginBottom: '10px',
    },
    svgIcon: {
        display: 'inline',
        width: '50px',
        margin: '0 20px 5px 10px',
    },
    formSubtitle: {
        fontWeight: 'bold',
        textTransform: 'uppercase',
        display: 'inline',
        position: 'absolute',
        transform: 'translateY(9px)',
        [theme.breakpoints.down(573)]: {
            fontSize: 'medium',
            transform: 'translateY(13px)',
        },
        [theme.breakpoints.down(475)]: {
            fontSize: 'small',
            transform: 'translateY(16px)',
        },
        [theme.breakpoints.down(423)]: {
            transform: 'translateY(7px)',
        },
    },
    formContainer: {
        border: 'solid 1px #484850',
        borderRadius: '5px',
    },
    formControlCpuSmall: {
        width: '35%',
        marginLeft: '2%',
        marginRight: '3%',
        [theme.breakpoints.down('xs')]: {
            width: '40%',
        },
    },
    formControlCpuLarge: {
        width: '58%',
        marginRight: '2%',
        [theme.breakpoints.down('xs')]: {
            width: '53%',
        },
    },
    formControlSmall: {
        marginLeft: '3%',
        width: '17.7%',
    },
    formControlMedium: {
        marginLeft: '2%',
        marginRight: '3%',
        width: '70%',
        [theme.breakpoints.down(509)]: {
            width: '67%',
        },
    },
    formControlLarge: {
        width: '96%',
        marginLeft: '2%',
        marginRight: '2%',
    },
    gpuSubForm: {
        marginTop: '15px',
    },
    timesIcon: {
        transform: 'translateY(15px)',
        fontSize: '25px',
    },
    calculatorContainer: {
        position: 'sticky',
        top: '10px',
        paddingBottom: 0,
    },
});
